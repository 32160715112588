export const adminPermission = {
  auth: {
    read: 'admin.auth.read',
    write: 'admin.auth.write'
  },
  userAccount: {
    read: 'admin.userAccount.read',
    write: 'admin.userAccount.write',
    resetPwd: 'admin.userAccount.resetPwd',
    setActive: 'admin.userAccount.setActive',
    changeRole: 'admin.userAccount.changeRole'
  },
  sector: {
    read: 'admin.sector.read',
    write: 'admin.sector.write',
    delete: 'admin.sector.delete'
  },
  applicationService: {
    read: 'admin.applicationService.read',
    write: 'admin.applicationService.write',
    delete: 'admin.applicationService.delete'
  },
  applicationRequest: {
    read: 'admin.applicationRequest.read',
    approval: 'admin.applicationRequest.approval',
    changeDepartment: 'admin.applicationRequest.changeDepartment',
    changeSector: 'admin.applicationRequest.changeSector',
    reject: 'admin.applicationRequest.reject',
    markAsDeliver: 'admin.applicationRequest.markAsDeliver',
    suspendReopen: 'admin.applicationRequest.suspendReopen'
  },
  documentForm: {
    read: 'admin.documentForm.read',
    write: 'admin.documentForm.write',
    delete: 'admin.documentForm.delete'
  },
  department: {
    read: 'admin.department.read',
    write: 'admin.department.write',
    delete: 'admin.department.delete'
  },
  departmentServiceLevel: {
    read: 'admin.departmentServiceLevel.read',
    write: 'admin.departmentServiceLevel.write',
    delete: 'admin.departmentServiceLevel.delete'
  },
  role: {
    read: 'admin.role.read',
    write: 'admin.role.write',
    delete: 'admin.role.delete'
  },
  reportData: {
    dashboard: 'admin.reportData.dashboard',
    serviceRequestCount: 'admin.reportData.serviceRequestCount',
    departmentRequestCount: 'admin.reportData.departmentRequestCount'
  },
  fileImport: {
    applicationService: 'admin.fileImport.applicationService'
  }
};
